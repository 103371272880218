import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Modal, Table } from "antd";
import { useEffect, useState } from "react";
//Api
import queryString from "query-string";
import DataService from "../../services/requestApi";
import JsonToXlsxDownload from "./JsonToXlsxDownload";
import moment from "moment";
import { Link } from "react-router-dom";
import AddApplicant from "components/Applicant/AddApplicant";

const Master = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [userIds, setUserIds] = useState([]); // for multiple users report download
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    school_name: "",
    school_city: "",
    school_code: "",
  });
  //pagination
  const [pageData, setPageData] = useState({
    pageNo: 1,
    limit: 20,
  });
  const [totalCounts, setTotalCounts] = useState(null);

  useEffect(() => {
    GetData();
  }, [pageData.limit, pageData.pageNo]);
  const GetData = async () => {
    try {
      setLoading(true);
      const { data } = await DataService.FindAllUsersByParams(pageData);
      if (data?.message === "Success") {
        const newdata = [];
        data?.admins?.map((item, index) => {
          item.key = index;
          newdata.push(item);
        });
        setData(newdata);
        setTotalCounts(data?.totalCount);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };
  const CreatAdmin = async (e) => {
    e.preventDefault();
    try {
      console.log(userData);
      setIsSubmit(true);
      const { data } = await DataService.CreateAdmin(
        queryString.stringify(userData)
      );
      setIsSubmit(false);
      console.log(data);
    } catch (error) {
      setIsSubmit(false);
      console.log(error);
    }
  };
  const filter = data?.filter((item) => {
    const email = item?.email;
    const mobile = item?.mobile;
    const emailSearch = email?.toLowerCase()?.includes(search.toLowerCase());
    const mobileSearch = mobile?.toLowerCase()?.includes(search.toLowerCase());
    if (emailSearch) return emailSearch;
    if (mobileSearch) return mobileSearch;
  });

  const handleEdit = async (id, status, name) => {
    try {
      const formdata = new FormData();
      if (name === "status") {
        formdata.append("status", status ? false : true);
      } else {
        formdata.append("isChecked", status ? false : true);
      }
      const { data } = await DataService.UpdateStatus(id, formdata);

      if (data.message === "Success") {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    {
      title: "Sr No.",
      dataIndex: "name",
      key: "_id" + 1,
      render: (id, item, idx) => idx + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (id, item) => (
        <>
          {item.firstName} {item.lastName}
        </>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("ll"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (id, item) => (
        <Label className="custom-toggle custom-toggle-warning">
          <Input
            onClick={() => handleEdit(item?._id, item?.status, "status")}
            defaultChecked={item?.status}
            type="checkbox"
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="Off"
            data-label-on="On"
          />
        </Label>
      ),
    },
    {
      title: "Checked",
      dataIndex: "isChecked",
      key: "isChecked",
      render: (id, item) => (
        <Label className="custom-toggle custom-toggle-warning">
          <Input
            onClick={() => handleEdit(item?._id, item?.isChecked, "checked")}
            defaultChecked={item?.isChecked}
            type="checkbox"
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="Off"
            data-label-on="On"
          />
        </Label>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (id) => (
        <Button tag={Link} to={`/admin/admin-listing/${id}`} size="sm">
          <i className="fas fa-eye text-dark" />
        </Button>
      ),
    },
  ];
  const handleReportSelections = (data) => {
    // debugger;
    let idArray = [];
    if (Array?.isArray(data) === false) {
      idArray = [...userIds];
      let selectedId = userIds?.filter((element) => element === data?._id);
      if (selectedId.length > 0) {
        const idIndex = idArray?.indexOf(selectedId);
        idArray?.splice(idIndex, 1);
        setUserIds(idArray);
      } else {
        idArray.push(data?._id);
        setUserIds(idArray);
      }
    } else {
      data?.forEach((element) => {
        idArray.push(element?._id);
        // console.log(element);
      });
      setUserIds(idArray);
    }
  };
  return (
    <>
      <Container className="my-5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 pb-0 d-flex align-items-center justify-content-between">
                <h3 className="mb-0">User List</h3>
                <div className="d-flex align-items-center">
                  <Button
                    color="dark"
                    size="sm"
                    onClick={() => setModal(!modal)}
                  >
                    Add Applicant
                  </Button>
                  <div className="xlsxDownloadBtn mr-3">
                    <JsonToXlsxDownload jsonData={data} />
                  </div>
                  {/* button to download reports it sends array of userid/userids to DownloadMultipleReoprts component for fetching reports */}
                  <Link
                    className="btn-dark btn btn-sm"
                    to="/admin/index/user-profile/multiple-reports"
                    state={userIds}
                  >
                    Download selected reports
                  </Link>
                  <div className="search-container">
                    <Input
                      placeholder="Search"
                      className="rounded rounded-pill"
                      value={search}
                      type="search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button className="search_btn">
                      <i className="fas fa-search" />
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  rowSelection={{
                    type: "checkbox",
                    onSelect: (record) => {
                      handleReportSelections(record);
                    },
                    onSelectAll: (selected, selectedRows) => {
                      handleReportSelections(selectedRows);
                    },
                    onSelectMultiple: (selected, selectedRows) => {
                      handleReportSelections(selectedRows);
                    },
                  }}
                  dataSource={filter}
                  columns={columns}
                  loading={loading}
                  pagination={{
                    current: pageData.pageNo,
                    pageSize: pageData.limit,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: totalCounts,
                    onChange: (page, pageSize) => {
                      setPageData({
                        ...pageData,
                        pageNo: page,
                        limit: pageSize,
                      });
                      // GetData();
                    },
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        title="Add Applicant"
        open={modal}
        onCancel={() => setModal(!modal)}
        footer={null}
        width={600}
      >
        <AddApplicant
          handleChange={handleChange}
          createAdmin={CreatAdmin}
          loading={isSubmit}
        />
      </Modal>
    </>
  );
};

export default Master;
