import axios from "axios";
export const BASEURL = {
  ENDPOINT_URL: "https://api.dakshtest.com/api/",
};
const AuthToken = localStorage.getItem("token");
// console.log(AuthToken);
export default axios.create({
  baseURL: `${BASEURL.ENDPOINT_URL}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    Authorization: `Bearer ${AuthToken}`,
  },
});
// console.log(localStorage.getItem("token"));
