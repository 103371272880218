import Profile from "views/examples/Profile.js";
import Login from "views/examples/Login.js";
import Icons from "views/examples/Icons";
import Master from "views/examples/Master";
import AdminListing from "views/examples/AdminListing";
import Report from "views/examples/Report";
import DownloadMultipleReoprts from "views/examples/DownloadMultipleReoprts";

var routes = [
  {
    path: "/",
    name: "Users Listing",
    icon: "fas fa-user-tie text-blue",
    component: <Master />,
    layout: "/admin",
    show: true,
  },
  {
    path: "/admin-listing/:id",
    name: "Admins",
    icon: "	fas fa-user-tie text-blue",
    component: <AdminListing />,
    show: false,
    layout: "/admin",
  },

  {
    path: "/index/user-profile/:id",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    show: false,
    layout: "/admin",
  },
  {
    path: "/index/user-profile/report/:id",
    name: "Report",
    component: <Report />,
    show: false,
    layout: "/admin",
  },
  {
    path: "/index/user-profile/multiple-reports",
    name: "Multiple Reoprts",
    component: <DownloadMultipleReoprts />,
    show: false,
    layout: "/admin",
  },
  // {
  //   path: "/transactions",
  //   name: "Transactions",
  //   icon: "fas fa-money-bill-wave text-green",
  //   component: <Transactions />,
  //   show: true,
  //   layout: "/admin",
  // },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    show: false,
    layout: "/auth",
  },
  {
    path: "/icon",
    name: "Icon",
    icon: "ni ni-circle-08 text-pink",
    component: <Icons />,
    layout: "/auth",
    show: false,
  },
];
export default routes;
