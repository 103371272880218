import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
const AddApplicant = ({ handleChange, createAdmin, loading }) => {
  return (
    <Form className="mt-3" onSubmit={createAdmin}>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="firstname">First Name</Label>
            <Input
              id="firstname"
              name="firstName"
              onChange={handleChange}
              placeholder="Email first name"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="lastName">Last Name</Label>
            <Input
              id="lastName"
              name="lastName"
              onChange={handleChange}
              placeholder="Email last name"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="exampleEmail">Email</Label>
            <Input
              id="exampleEmail"
              name="email"
              onChange={handleChange}
              placeholder="Email"
              type="email"
              autoComplete="new-email"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="mobile">Mobile</Label>
            <Input
              id="mobile"
              name="mobile"
              onChange={handleChange}
              placeholder="Email Mobile"
              type="tel"
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="mb-3">
            <Label for="password">Password</Label>
            <Input
              id="password"
              name="password"
              onChange={handleChange}
              placeholder="Email Password"
              type="password"
              autoComplete="new-password"
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="mb-3">
            <Label for="school_name">School Name</Label>
            <Input
              id="school_name"
              name="school_name"
              onChange={handleChange}
              placeholder="Email school name"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="school_city">School City</Label>
            <Input
              id="school_city"
              name="school_city"
              onChange={handleChange}
              placeholder="Email city"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-4">
            <Label for="school_code">School Code</Label>
            <Input
              id="school_code"
              name="school_code"
              onChange={handleChange}
              placeholder="Email school code"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="mb-3">
            <Button
              color="dark"
              type="submit"
              className="w-100"
              block
              disabled={loading}
            >
              {loading && <Spinner size="sm" className="mr-2" />} Submit
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
export default AddApplicant;
