import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import DataService from "../../services/requestApi";
import { Modal } from "antd";
import * as XLSX from "xlsx"; // Import all functions and objects from 'xlsx'

const JsonToXlsxDownload = ({ pageName, subUserId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const removeKeys = (obj, keysToRemove) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => {
      delete newObj[key];
    });
    return newObj;
  };
  // Function to format date using moment
  const formatDate = (dateString) => {
    return moment(dateString).format("LL");
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleDownload = async (whole) => {
    let jsonData = null;
    let filteredData = null;
    if (whole === "whole") {
      try {
        if (pageName === "AdminListing") {
          const { data } = await DataService.GetAdminByAdminId(
            `adminId=${subUserId}`
          );
          if (data?.message === "Success") {
            jsonData = data?.UserData;
          }
        } else {
          const { data } = await DataService.FindAllUsers();
          if (data?.message === "Success") {
            jsonData = data?.admins;
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        if (pageName === "AdminListing") {
          const { data } = await DataService.GetAdminByAdminId(
            `adminId=${subUserId}&startDate=${selectedDate}`
          );
          if (data?.message === "Success") {
            jsonData = data?.UserData;
          }
        } else {
          const { data } = await DataService.FindAllUsersByParams(
            `startDate=${selectedDate}`
          );
          if (data?.message === "Success") {
            filteredData = data?.admins;
            setSelectedDate("");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    // Filter data based on selected date

    const data = whole ? jsonData : filteredData;
    let keysToRemove = [];
    if (pageName === "AdminListing") {
      keysToRemove = [
        "dataName",
        "adminId",
        "_id",
        "__v",
        "password",
        "updated_at",
        "created_at",
        "fingerLeftIndex",
        "fingerLeftLittle",
        "fingerLeftMiddle",
        "fingerLeftRing",
        "fingerLeftThumb",
        "fingerRightIndex",
        "fingerRightLittle",
        "fingerRightMiddle",
        "fingerRightRing",
        "fingerRightThumb",
        "isChecked",
        "isComplete",
        "status",
        "personality",
      ];
    } else {
      keysToRemove = ["role", "status", "_id", "__v", "password", "updated_at"];
    }
    const modifiedData = await data?.map((item) => ({
      ...removeKeys(item, keysToRemove),
      dob: formatDate(item?.dob),
      primary: item?.personality?.primary,
      secondary: item?.personality?.secondary,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(modifiedData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.writeFile to trigger the download
    XLSX.writeFile(wb, "data.xlsx");
  };

  const showModal = () => {
    setIsModalOpen(isModalOpen ? false : true);
  };

  return (
    <div>
      <Button onClick={showModal} color="dark" size="sm">
        Download XLSX
      </Button>
      <Modal
        title="Download Excel"
        open={isModalOpen}
        footer={null}
        onCancel={showModal}
        className="jsontoxlsx"
      >
        <Button
          onClick={() => {
            handleDownload("whole");
            showModal();
          }}
          color="dark"
          className="w-100 mb-4 mt-2"
        >
          Whole data download
        </Button>
        <h3 className="text-dark">Sort by date</h3>
        <FormGroup className="my-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58 text-dark" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </InputGroup>
        </FormGroup>
        <Button
          onClick={() => {
            handleDownload();
            showModal();
          }}
          color="dark"
          className="w-100 "
        >
          Download
        </Button>
      </Modal>
    </div>
  );
};

export default JsonToXlsxDownload;
