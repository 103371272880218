import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import DataService from "../../services/requestApi";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import JsonToXlsxDownload from "./JsonToXlsxDownload";

const AdminListing = () => {
  const ID = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetData();
  }, []);

  const GetData = async () => {
    try {
      setLoading(true);
      const { data } = await DataService.GetAdminByAdminId(`adminId=${ID?.id}`);
      if (data?.message === "Success") {
        const newdata = [];
        data?.UserData?.map((item, index) => {
          item.key = index;
          newdata.push(item);
        });
        setData(newdata);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleEdit = async (id, status) => {
    try {
      const formdata = new FormData();
      formdata.append("isChecked", status ? false : true);
      const { data } = await DataService.UpdateUser(id, formdata);

      if (data.message === "Success") {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Container className="my-5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 pb-0 d-flex align-items-center justify-content-between">
                <h3 className="mb-0">Admin List</h3>
                <div className="xlsxDownloadBtn mr-3">
                  <JsonToXlsxDownload
                    jsonData={data}
                    pageName={"AdminListing"}
                    subUserId={ID?.id}
                  />
                </div>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="d-flex justify-content-center my-4">
                    <Spinner />
                  </div>
                ) : data.length > 0 ? (
                  <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Primary personality</th>
                        <th scope="col">Secondary personality</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Class</th>
                        <th scope="col" className="text-center">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.dataName}</td>
                            <td>{item?.personality?.primary}</td>
                            <td>{item?.personality?.secondary}</td>
                            <td>{item?.mobile ? item?.mobile : ""}</td>
                            <td>{item?.class ? item?.class : ""}</td>
                            <td className="text-center">
                              <Label className="custom-toggle custom-toggle-warning">
                                <Input
                                  onClick={() =>
                                    handleEdit(item?._id, item?.isChecked)
                                  }
                                  defaultChecked={item?.isChecked}
                                  type="checkbox"
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="Off"
                                  data-label-on="On"
                                />
                              </Label>
                            </td>
                            <td className="text-center">
                              <Button
                                tag={Link}
                                to={`/admin/index/user-profile/${item?._id}`}
                                size="sm"
                              >
                                <i className="fas fa-eye text-dark" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <h3>No Data Found</h3>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AdminListing;
