import { Button, Card, CardBody, Container, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataService from "../../services/requestApi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Pie, Bar } from "react-chartjs-2";
import R1 from "../../assets/img/allimages/new-1-min.jpg";
import R2 from "../../assets/img/allimages/new-2-min.jpg";
import R3 from "../../assets/img/allimages/new-3-min.jpg";
import R4 from "../../assets/img/allimages/new-4-min.jpg";
import R5 from "../../assets/img/allimages/new-5-min.jpg";
import R6 from "../../assets/img/allimages/new-6-min.jpg";
import R7 from "../../assets/img/allimages/new-7-min.jpg";
import R8 from "../../assets/img/allimages/new-8-min.jpg";
import R9 from "../../assets/img/allimages/new-9-min.jpg";
import R10 from "../../assets/img/allimages/new-10-min.jpg";
import R11 from "../../assets/img/allimages/new-11-min.jpg";
import R12 from "../../assets/img/allimages/new-12-min.jpg";
import R13 from "../../assets/img/allimages/new-13-min.jpg";
import R14 from "../../assets/img/allimages/new-14-min.jpg";
import R15 from "../../assets/img/allimages/new-15-min.jpg";
import R16 from "../../assets/img/allimages/new-16-min.jpg";
import R17 from "../../assets/img/allimages/new-17-min.jpg";
import R18 from "../../assets/img/allimages/new-18-min.jpg";
import R19 from "../../assets/img/allimages/new-19-min.jpg";
import R20 from "../../assets/img/allimages/new-20-min.jpg";
import R21 from "../../assets/img/allimages/new-21-min.jpg";
import R22 from "../../assets/img/allimages/new-22-min.jpg";
import R23 from "../../assets/img/allimages/new-23-min.jpg";
import R24 from "../../assets/img/allimages/new-24-min.jpg";
import R25 from "../../assets/img/allimages/new-25-min.jpg";
import R26 from "../../assets/img/allimages/new-26-min.jpg";
import R27 from "../../assets/img/allimages/new-27-min.jpg";
import R28 from "../../assets/img/allimages/new-28-min.jpg";
import R29 from "../../assets/img/allimages/new-29-min.jpg";
import R30 from "../../assets/img/allimages/new-30-min.jpg";
import R31 from "../../assets/img/allimages/new-31-min.jpg";
import R32 from "../../assets/img/allimages/new-32-min.jpg";
import R33 from "../../assets/img/allimages/new-33-min.jpg";
import R34 from "../../assets/img/allimages/new-34-min.jpg";
import R35 from "../../assets/img/allimages/new-35-min.jpg";
import R36 from "../../assets/img/allimages/new-36-min.jpg";
import R37 from "../../assets/img/allimages/new-37-min.jpg";
import R38 from "../../assets/img/allimages/new-38-min.jpg";
import R39 from "../../assets/img/allimages/new-39-min.jpg";
import R40 from "../../assets/img/allimages/new-40-min.jpg";
import R41 from "../../assets/img/allimages/new-41-min.jpg";
import R42 from "../../assets/img/allimages/new-42-min.jpg";
import R43 from "../../assets/img/allimages/new-43-min.jpg";
import R44 from "../../assets/img/allimages/new-44-min.jpg";

import { chartOptions, parseOptions, chartExample2 } from "variables/charts.js";

const Report = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const ID = useParams();

  useEffect(() => {
    getData();
  }, []);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await DataService.GetReportByUserDataId(ID?.id);
      if (data.message === "Success") {
        setData(data?.Report);
        setLoading(false);
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setLoading(false);
    }
  };
  const CareerFormation = (value) => {
    value = value * 100;
    if (value === NaN) {
      return;
    } else if (value < 8) {
      return "*";
    } else if (value == 8) {
      return "**";
    } else if (value > 8 && value <= 9) {
      return "***";
    } else if (value > 9 && value <= 10) {
      return "****";
    } else {
      return "*****";
    }
  };

  const captureAndConvertPages = async () => {
    setLoader(true);
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [297, 210],
    });
    const imageQuality = 0.7;
    const pagesToCapture = document.querySelectorAll(".pageToCapture");

    const capturePage = async (pageIndex) => {
      if (pageIndex < pagesToCapture.length) {
        const page = pagesToCapture[pageIndex];
        const canvas = await html2canvas(page);
        const imgData = canvas.toDataURL("image/jpeg", imageQuality);

        if (pageIndex > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "JPEG", 0, 0, 297, 210);

        capturePage(pageIndex + 1);
      } else {
        pdf.save("custom-page-size-pdf.pdf");
        setLoader(false);
      }
    };

    capturePage(0);
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt-5 mb-3 " fluid>
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Card className="report">
            <CardBody>
              <div className="pdf">
                <div className="pageToCapture">
                  <img src={R1} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R2} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R4} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R5} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R6} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R7} alt="" className="img-fluid" />
                  <span className="absolute l7">
                    {data ? data[0]?.personality?.primary : ""}
                  </span>
                  <span className="absolute l8">
                    {data ? data[0]?.personality?.secondary : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R8} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R9} alt="" className="img-fluid" />
                  <span className="absolute l9">
                    {data ? data[0]?.tfrc : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R10} alt="" className="img-fluid" />
                  <span className="absolute l10">
                    {data
                      ? Math.round(
                        data[0]?.brain_dominance?.left_brain * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l11">
                    {data
                      ? Math.round(
                        data[0]?.brain_dominance?.right_brain * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R11} alt="" className="img-fluid" />
                </div>
                <div className="pageToCapture">
                  <img src={R12} alt="" className="img-fluid" />
                  <span className="absolute l12">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.intrapersonal?.self *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l13">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.intrapersonal
                          ?.planning * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l14">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.intrapersonal
                          ?.emotion * 1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Planning", "Emotion"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.intrapersonal?.planning * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.intrapersonal?.emotion * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R13} alt="" className="img-fluid" />
                  <span className="absolute l15">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.logical_mathematical
                          ?.self * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l16">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.logical_mathematical
                          ?.reasoning * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l17">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.logical_mathematical
                          ?.numerical * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l18">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.logical_mathematical
                          ?.geometry * 1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Reasoning", "Numerical", "Geometry"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.logical_mathematical?.reasoning * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.logical_mathematical?.numerical * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.logical_mathematical?.geometry * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R14} alt="" className="img-fluid" />
                  <span className="absolute l15">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.linguistic?.self *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l16">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.linguistic
                          ?.nonVerbal * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l19">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.linguistic?.verbal *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l18">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.linguistic?.writing *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Non Verbal", "Verbal", "Writing"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.linguistic
                                    ?.nonVerbal * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.linguistic
                                    ?.verbal * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.linguistic
                                    ?.writing * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R15} alt="" className="img-fluid" />
                  <span className="absolute l20">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.naturalist?.self *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l21">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.naturalist
                          ?.observation * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l22">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.naturalist?.senses *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Observation", "Senses"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.naturalist
                                    ?.observation * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.naturalist
                                    ?.senses * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R16} alt="" className="img-fluid" />
                  <span className="absolute l23">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.interpersonal?.self *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l24">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.interpersonal
                          ?.social * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l25">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.interpersonal
                          ?.motivation * 1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["social", "Motivation"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.interpersonal?.social * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.interpersonal?.motivation * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R17} alt="" className="img-fluid" />
                  <span className="absolute l26">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.visual?.self * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l27">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.visual?.ideas * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l28">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.visual?.sprituals *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c2 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["3D Space", "Sprituals"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.visual
                                    ?.ideas * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.visual
                                    ?.sprituals * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R18} alt="" className="img-fluid" />
                  <span className="absolute l29">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.musical?.self * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l16">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.musical?.voiceTone *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l30">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.musical?.processing *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l18">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.musical?.auditory *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c2 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Voice Tone", "Processing", "Auditory"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.musical
                                    ?.voiceTone * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.musical
                                    ?.processing * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence?.musical
                                    ?.auditory * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R19} alt="" className="img-fluid" />
                  <span className="absolute l26">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.kinesthetic?.self *
                        1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l31">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.kinesthetic
                          ?.fineMotor * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l28">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.kinesthetic
                          ?.vGrossMotor * 1000
                      ) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Fine Motor", "Gross Motor"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.kinesthetic?.fineMotor * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.multiple_intelligence
                                    ?.kinesthetic?.vGrossMotor * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R20} alt="" className="img-fluid" />
                  <span className="absolute l32">
                    {data ? Math.round(data[0]?.senses?.taste * 1000) / 10 : ""}
                  </span>
                  <span className="absolute l33">
                    {data ? Math.round(data[0]?.senses?.smell * 1000) / 10 : ""}
                  </span>
                  <span className="absolute l34">
                    {data ? Math.round(data[0]?.senses?.touch * 1000) / 10 : ""}
                  </span>
                  <span className="absolute l35">
                    {data
                      ? Math.round(data[0]?.senses?.listen * 1000) / 10
                      : ""}
                  </span>
                  <span className="absolute l36">
                    {data ? Math.round(data[0]?.senses?.see * 1000) / 10 : ""}
                  </span>
                  <div
                    className="chart absolute c2 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Taste", "Smell", "Touch", "Listen", "See"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(data[0]?.senses?.taste * 1000) /
                                10
                                : ""
                              }`,
                              `${data
                                ? Math.round(data[0]?.senses?.smell * 1000) /
                                10
                                : ""
                              }`,
                              `${data
                                ? Math.round(data[0]?.senses?.touch * 1000) /
                                10
                                : ""
                              }`,
                              `${data
                                ? Math.round(data[0]?.senses?.listen * 1000) /
                                10
                                : ""
                              }`,
                              `${data
                                ? Math.round(data[0]?.senses?.see * 1000) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R21} alt="" className="img-fluid" />
                  <div
                    className="chart absolute c2 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: [
                          "Action",
                          "Thinking",
                          "Tactile",
                          "Auditory",
                          "Visual",
                        ],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.thought_process?.action * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.thought_process?.thinking * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.thought_process?.tactile * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.thought_process?.auditory * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.thought_process?.visual * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R22} alt="" className="img-fluid" />
                  <span className="absolute l37">
                    {data
                      ? Math.round(
                        data[0]?.object_concept?.object_driven * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l38">
                    {data
                      ? Math.round(
                        data[0]?.object_concept?.concept_driven * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l39">
                    {data
                      ? Math.round(data[0]?.executor_thinker?.executor * 1000) /
                      10
                      : ""}
                  </span>
                  <span className="absolute l40">
                    {data
                      ? Math.round(data[0]?.executor_thinker?.thinker * 1000) /
                      10
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R23} alt="" className="img-fluid" />
                  <span className="absolute l41">
                    {data ? Math.round(data[0]?.quotients?.iq) : ""}
                  </span>
                  <span className="absolute l42">
                    {data ? Math.round(data[0]?.quotients?.eq) : ""}
                  </span>
                  <span className="absolute l43">
                    {data ? Math.round(data[0]?.quotients?.cq) : ""}
                  </span>
                  <span className="absolute l44">
                    {data ? Math.round(data[0]?.quotients?.aq) : ""}
                  </span>
                  <div
                    className="chart absolute c3 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["IQ", "EQ", "CQ", "AQ"],
                        datasets: [
                          {
                            data: [
                              `${data ? Math.round(data[0]?.quotients?.iq) : ""
                              }`,
                              `${data ? Math.round(data[0]?.quotients?.eq) : ""
                              }`,
                              `${data ? Math.round(data[0]?.quotients?.cq) : ""
                              }`,
                              `${data ? Math.round(data[0]?.quotients?.aq) : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R24} alt="" className="img-fluid" />
                  <span className="absolute l45">
                    {data
                      ? Math.round(data[0]?.learning_style?.visual * 1000) / 10
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R25} alt="" className="img-fluid" />
                  <span className="absolute l46">
                    {data
                      ? Math.round(data[0]?.learning_style?.auditory * 1000) /
                      10
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R26} alt="" className="img-fluid" />
                  <span className="absolute l47">
                    {data
                      ? Math.round(
                        data[0]?.learning_style?.kinesthetic * 1000
                      ) / 10
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R27} alt="" className="img-fluid" />
                  <span className="absolute l48">
                    {data
                      ? Math.round(data[0]?.leadership_style?.task_oriented)
                      : ""}
                  </span>
                  <span className="absolute l49">
                    {data
                      ? Math.round(
                        data[0]?.leadership_style?.relationship_oriented
                      )
                      : ""}
                  </span>
                  <div
                    className="chart absolute c1 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Task Oriented", "Relationship Oriented"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.leadership_style?.task_oriented
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.leadership_style
                                    ?.relationship_oriented
                                )
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R28} alt="" className="img-fluid" />
                  <span className="absolute l50">
                    {data
                      ? Math.round(data[0]?.holland_theory?.realistic * 1000) /
                      10
                      : ""}
                  </span>
                  <span className="absolute l51">
                    {data
                      ? Math.round(
                        data[0]?.holland_theory?.investigative * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l52">
                    {data
                      ? Math.round(
                        data[0]?.holland_theory?.conventional * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l53">
                    {data
                      ? Math.round(data[0]?.holland_theory?.artistic * 1000) /
                      10
                      : ""}
                  </span>
                  <span className="absolute l54">
                    {data
                      ? Math.round(
                        data[0]?.holland_theory?.enterprising * 1000
                      ) / 10
                      : ""}
                  </span>
                  <span className="absolute l55">
                    {data
                      ? Math.round(data[0]?.holland_theory?.social * 1000) / 10
                      : ""}
                  </span>
                  <div
                    className="chart absolute c2 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: [
                          "Realistic",
                          "Investigative",
                          "Conventional",
                          "Artistic",
                          "Enterprising",
                          "Social",
                        ],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.holland_theory?.realistic * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.holland_theory?.investigative *
                                  1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.holland_theory?.conventional *
                                  1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.holland_theory?.artistic * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.holland_theory?.enterprising *
                                  1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.holland_theory?.social * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R29} alt="" className="img-fluid" />
                  <div
                    className="chart absolute c2 p-2"
                    style={{
                      width: "400px",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["Analytic", "Introspective", "Interactive"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.mckenzie_theory?.analytic * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.mckenzie_theory?.introspective *
                                  1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.mckenzie_theory?.interactive *
                                  1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 40,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R30} alt="" className="img-fluid" />
                  <span className="absolute l56">
                    {data ? data[0]?.acquiring_methods?.reverse : ""}
                  </span>
                  <span className="absolute l57">
                    {data ? data[0]?.acquiring_methods?.self_cognitive : ""}
                  </span>
                  <span className="absolute l58">
                    {data ? data[0]?.acquiring_methods?.affective : ""}
                  </span>
                  <span className="absolute l59">
                    {data ? data[0]?.acquiring_methods?.reflective : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R31} alt="" className="img-fluid" />
                  <div
                    className="chart absolute c3 p-2"
                    style={{
                      width: "600px",
                      height: "170px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: [
                          "dance",
                          "instrumental_music",
                          "singing",
                          "acting",
                          "swimming",
                          "horse_riding",
                          "chess",
                          "snooker",
                          "foreign_language",
                          "painting",
                        ],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.dance * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.instrumental_music * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.singing * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.acting * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.swimming * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.horse_riding * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.chess * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.snooker * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.foreign_language * 1000
                                ) / 10
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.extra_curricular_activities
                                    ?.painting * 1000
                                ) / 10
                                : ""
                              }`,
                            ],
                            maxBarThickness: 30,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R32} alt="" className="img-fluid" />
                  <span className="absolute l60">
                    {data
                      ? Math.round(data[0]?.stream_selection?.science * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l61">
                    {data
                      ? Math.round(
                        data[0]?.stream_selection?.commerce * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l62">
                    {data
                      ? Math.round(data[0]?.stream_selection?.arts * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <div
                    className="chart absolute c6 p-2"
                    style={{ width: "250px", height: "250px" }}
                  >
                    <Pie
                      data={{
                        labels: ["Science", "Commerce", "Arts"],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.stream_selection?.science * 10000
                                ) / 100
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.stream_selection?.commerce *
                                  10000
                                ) / 100
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.stream_selection?.arts * 10000
                                ) / 100
                                : ""
                              }`,
                            ],
                            backgroundColor: ["#2e9db8", "#dc1c28", "#e9601a"],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          tooltips: {
                            enabled: true,
                            callbacks: {
                              label: function (context) {
                                const label = context.label || "";
                                const value = context.parsed;
                                return `${label}: ${value}%`;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R33} alt="" className="img-fluid" />
                  <span className="absolute l63">
                    {data
                      ? CareerFormation(data[0]?.career?.medical?.doctor)
                      : ""}
                  </span>
                  <span className="absolute l64">
                    {data
                      ? CareerFormation(data[0]?.career?.medical?.pharmacist)
                      : ""}
                  </span>
                  <span className="absolute l65">
                    {data
                      ? CareerFormation(data[0]?.career?.medical?.nutritionist)
                      : ""}
                  </span>
                  <span className="absolute l66">
                    {data
                      ? CareerFormation(data[0]?.career?.medical?.drug_officer)
                      : ""}
                  </span>
                  <span className="absolute l67">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.medical?.medical_officer
                      )
                      : ""}
                  </span>
                  <span className="absolute l68">
                    {data
                      ? CareerFormation(data[0]?.career?.medical?.surgeon)
                      : ""}
                  </span>
                  <span className="absolute l69">
                    {data
                      ? CareerFormation(data[0]?.career?.medical?.chemist)
                      : ""}
                  </span>
                  <span className="absolute l70">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.medical?.food_inspector
                      )
                      : ""}
                  </span>
                  <span className="absolute l71">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.chemical_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l72">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.mechanical_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l73">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.electronical_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l74">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.computer_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l75">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.electrical_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l76">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.production_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l77">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.aviation_engineering
                      )
                      : ""}
                  </span>
                  <span className="absolute l78">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.engineering?.sound_engineering
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R34} alt="" className="img-fluid" />
                  <span className="absolute l63">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.music_teacher)
                      : ""}
                  </span>
                  <span className="absolute l64">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.music_composer)
                      : ""}
                  </span>
                  <span className="absolute l65">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.music_director)
                      : ""}
                  </span>
                  <span className="absolute l66">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.singer)
                      : ""}
                  </span>
                  <span className="absolute l67">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.music?.musical_instrument_artist
                      )
                      : ""}
                  </span>
                  <span className="absolute l68">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.piano_artist)
                      : ""}
                  </span>
                  <span className="absolute l69">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.music?.voice_recognizer
                      )
                      : ""}
                  </span>
                  <span className="absolute l70">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.guitar_artist)
                      : ""}
                  </span>
                  <span className="absolute l79">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.dancer)
                      : ""}
                  </span>
                  <span className="absolute l80">
                    {data
                      ? CareerFormation(data[0]?.career?.music?.choreographer)
                      : ""}
                  </span>
                  <span className="absolute l73">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.life_science?.biology_teacher
                      )
                      : ""}
                  </span>
                  <span className="absolute l74">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.life_science?.pathology_reseacher
                      )
                      : ""}
                  </span>
                  <span className="absolute l75">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.life_science
                          ?.biotechnology_researcher
                      )
                      : ""}
                  </span>
                  <span className="absolute l76">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.life_science?.vaternary_doctor
                      )
                      : ""}
                  </span>
                  <span className="absolute l77">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.life_science?.botany_professor
                      )
                      : ""}
                  </span>
                  <span className="absolute l78">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.life_science?.animal_plant_researcher
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R35} alt="" className="img-fluid" />
                  <span className="absolute l63">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.fashion_designer
                      )
                      : ""}
                  </span>
                  <span className="absolute l64">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.map_designer
                      )
                      : ""}
                  </span>
                  <span className="absolute l65">
                    {data
                      ? CareerFormation(data[0]?.career?.designing?.architect)
                      : ""}
                  </span>
                  <span className="absolute l66">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.interior_designer
                      )
                      : ""}
                  </span>
                  <span className="absolute l67">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.web_designer
                      )
                      : ""}
                  </span>
                  <span className="absolute l68">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.car_bike_designer
                      )
                      : ""}
                  </span>
                  <span className="absolute l69">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.software_designer
                      )
                      : ""}
                  </span>
                  <span className="absolute l70">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.designing?.wedding_planner
                      )
                      : ""}
                  </span>
                  <span className="absolute l71">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.geography_professor
                      )
                      : ""}
                  </span>
                  <span className="absolute l72">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.geo_science
                      )
                      : ""}
                  </span>
                  <span className="absolute l73">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.environment_researcher
                      )
                      : ""}
                  </span>
                  <span className="absolute l74">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.archeology
                      )
                      : ""}
                  </span>
                  <span className="absolute l75">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.soil_researcher
                      )
                      : ""}
                  </span>
                  <span className="absolute l76">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.water_harveter
                      )
                      : ""}
                  </span>
                  <span className="absolute l77">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.agriculture
                      )
                      : ""}
                  </span>
                  <span className="absolute l78">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.weather_environment_science
                          ?.forest_officer
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R36} alt="" className="img-fluid" />
                  <span className="absolute l63">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.chartered_account
                      )
                      : ""}
                  </span>
                  <span className="absolute l64">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.financial_officer
                      )
                      : ""}
                  </span>
                  <span className="absolute l65">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.insurance_agent
                      )
                      : ""}
                  </span>
                  <span className="absolute l66">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.tax_consultant
                      )
                      : ""}
                  </span>
                  <span className="absolute l67">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.investment_banker
                      )
                      : ""}
                  </span>
                  <span className="absolute l68">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.equity_reseacher
                      )
                      : ""}
                  </span>
                  <span className="absolute l69">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.wealth_manager
                      )
                      : ""}
                  </span>
                  <span className="absolute l70">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.banking_finance?.bank_teller
                      )
                      : ""}
                  </span>
                  <span className="absolute l71">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication
                          ?.language_expert
                      )
                      : ""}
                  </span>
                  <span className="absolute l72">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication?.newsreader
                      )
                      : ""}
                  </span>
                  <span className="absolute l73">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication
                          ?.anchor_radio_jockey
                      )
                      : ""}
                  </span>
                  <span className="absolute l74">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication?.poet
                      )
                      : ""}
                  </span>
                  <span className="absolute l75">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication
                          ?.writer_author
                      )
                      : ""}
                  </span>
                  <span className="absolute l76">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication?.journalist
                      )
                      : ""}
                  </span>
                  <span className="absolute l77">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication?.news_editor
                      )
                      : ""}
                  </span>
                  <span className="absolute l78">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mass_media_communication
                          ?.public_relation_officer
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R37} alt="" className="img-fluid" />
                  <span className="absolute l81">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.literature?.hindi_english_teacher
                      )
                      : ""}
                  </span>
                  <span className="absolute l82">
                    {data
                      ? CareerFormation(data[0]?.career?.literature?.poet)
                      : ""}
                  </span>
                  <span className="absolute l83">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.literature?.philosopher
                      )
                      : ""}
                  </span>
                  <span className="absolute l84">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.literature?.grammar_expert
                      )
                      : ""}
                  </span>
                  <span className="absolute l85">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.mathematician
                      )
                      : ""}
                  </span>
                  <span className="absolute l86">
                    {data
                      ? CareerFormation(data[0]?.career?.mathematics?.physicist)
                      : ""}
                  </span>
                  <span className="absolute l87">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.chemical_expert
                      )
                      : ""}
                  </span>
                  <span className="absolute l88">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.business_analyst
                      )
                      : ""}
                  </span>
                  <span className="absolute l89">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.accountant
                      )
                      : ""}
                  </span>
                  <span className="absolute l90">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.statistician
                      )
                      : ""}
                  </span>
                  <span className="absolute l91">
                    {data
                      ? CareerFormation(data[0]?.career?.mathematics?.actuary)
                      : ""}
                  </span>
                  <span className="absolute l92">
                    {data
                      ? CareerFormation(data[0]?.career?.mathematics?.economist)
                      : ""}
                  </span>
                  <span className="absolute l93">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.chartered_account
                      )
                      : ""}
                  </span>
                  <span className="absolute l94">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.mathematics?.company_secretary
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R38} alt="" className="img-fluid" />
                  <span className="absolute l81">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.management?.hr_management
                      )
                      : ""}
                  </span>
                  <span className="absolute l82">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.management?.marketing_management
                      )
                      : ""}
                  </span>
                  <span className="absolute l83">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.management?.business_management
                      )
                      : ""}
                  </span>
                  <span className="absolute l84">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.management?.production_management
                      )
                      : ""}
                  </span>
                  <span className="absolute l95">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.management?.tourism_management
                      )
                      : ""}
                  </span>
                  <span className="absolute l96">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.management?.sales_management
                      )
                      : ""}
                  </span>
                  <span className="absolute l97">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs?.politician
                      )
                      : ""}
                  </span>
                  <span className="absolute l98">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs
                          ?.ias_ips_officer
                      )
                      : ""}
                  </span>
                  <span className="absolute l99">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs?.lawyer
                      )
                      : ""}
                  </span>
                  <span className="absolute l100">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs
                          ?.teacher_professor
                      )
                      : ""}
                  </span>
                  <span className="absolute l101">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs?.judge
                      )
                      : ""}
                  </span>
                  <span className="absolute l102">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs
                          ?.administrator
                      )
                      : ""}
                  </span>
                  <span className="absolute l103">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs
                          ?.public_relation_officer
                      )
                      : ""}
                  </span>
                  <span className="absolute l104">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.public_political_affairs?.counselor
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R39} alt="" className="img-fluid" />
                  <span className="absolute l117">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.psychology?.psychologist
                      )
                      : ""}
                  </span>
                  <span className="absolute l118">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.psychology?.marriage_counselor
                      )
                      : ""}
                  </span>
                  <span className="absolute l119">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.psychology?.career_counselor
                      )
                      : ""}
                  </span>
                  <span className="absolute l120">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.psychology?.parenting_counselor
                      )
                      : ""}
                  </span>
                  <span className="absolute l105">
                    {data
                      ? CareerFormation(data[0]?.career?.psychology?.mediator)
                      : ""}
                  </span>
                  <span className="absolute l106">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.psychology?.spritual_leader
                      )
                      : ""}
                  </span>
                  <span className="absolute l107">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.translator
                      )
                      : ""}
                  </span>
                  <span className="absolute l108">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.language_teacher
                      )
                      : ""}
                  </span>
                  <span className="absolute l109">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.interpretor
                      )
                      : ""}
                  </span>
                  <span className="absolute l110">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.grammar_expert
                      )
                      : ""}
                  </span>
                  <span className="absolute l111">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.newsreader
                      )
                      : ""}
                  </span>
                  <span className="absolute l112">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.anchor
                      )
                      : ""}
                  </span>
                  <span className="absolute l113">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.trainer
                      )
                      : ""}
                  </span>
                  <span className="absolute l114">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language
                          ?.motivational_speaker
                      )
                      : ""}
                  </span>
                  <span className="absolute l115">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.language_researcher
                      )
                      : ""}
                  </span>
                  <span className="absolute l116">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.foreign_language?.speech_therapist
                      )
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R40} alt="" className="img-fluid" />
                  <span className="absolute l117">
                    {data
                      ? CareerFormation(data[0]?.career?.sport?.cricket)
                      : ""}
                  </span>
                  <span className="absolute l118">
                    {data
                      ? CareerFormation(data[0]?.career?.sport?.football)
                      : ""}
                  </span>
                  <span className="absolute l119">
                    {data
                      ? CareerFormation(data[0]?.career?.sport?.tennis)
                      : ""}
                  </span>
                  <span className="absolute l120">
                    {data
                      ? CareerFormation(data[0]?.career?.sport?.swimming)
                      : ""}
                  </span>
                  <span className="absolute l105">
                    {data
                      ? CareerFormation(data[0]?.career?.sport?.snooker)
                      : ""}
                  </span>
                  <span className="absolute l106">
                    {data ? CareerFormation(data[0]?.career?.sport?.carom) : ""}
                  </span>
                  <span className="absolute l107">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.army)
                      : ""}
                  </span>
                  <span className="absolute l108">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.air_force)
                      : ""}
                  </span>
                  <span className="absolute l109">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.police)
                      : ""}
                  </span>
                  <span className="absolute l110">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.crpf)
                      : ""}
                  </span>
                  <span className="absolute l111">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.defence?.fire_brigade_officer
                      )
                      : ""}
                  </span>
                  <span className="absolute l112">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.navy)
                      : ""}
                  </span>
                  <span className="absolute l113">
                    {data
                      ? CareerFormation(
                        data[0]?.career?.defence
                          ?.national_disaster_management_system
                      )
                      : ""}
                  </span>
                  <span className="absolute l114">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.nurse)
                      : ""}
                  </span>
                  <span className="absolute l115">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.athelete)
                      : ""}
                  </span>
                  <span className="absolute l116">
                    {data
                      ? CareerFormation(data[0]?.career?.defence?.shooter)
                      : ""}
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R41} alt="" className="img-fluid" />
                  <div
                    className="chart absolute c5 p-2"
                    style={{
                      width: "700px",
                      height: "450px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: [
                          "Medical",
                          "Engineering",
                          "Music",
                          "Life Science",
                          "Designing",
                          "Environment Science",
                          "Banking Finance",
                          "Mass Communication",
                          "Literature",
                          "Mathematics",
                          "Management",
                          "Public Affairs",
                          "Psychology",
                          "Foreign Language",
                          "Sport",
                          "Defence",
                        ],
                        datasets: [
                          {
                            data: [
                              `${data
                                ? Math.round(
                                  data[0]?.career?.medical?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.engineering?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.music?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.life_science?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.designing?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career
                                    ?.weather_environment_science?.self *
                                  100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.banking_finance?.self *
                                  100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.mass_media_communication
                                    ?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.literature?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.mathematics?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.management?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.public_political_affairs
                                    ?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.psychology?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.foreign_language?.self *
                                  100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.sport?.self * 100
                                )
                                : ""
                              }`,
                              `${data
                                ? Math.round(
                                  data[0]?.career?.defence?.self * 100
                                )
                                : ""
                              }`,
                            ],
                            maxBarThickness: 20,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R42} alt="" className="img-fluid" />
                  <span className="absolute l121">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.intrapersonal?.self *
                        10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l122">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.interpersonal?.self *
                        10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l123">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.logical_mathematical
                          ?.self * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l124">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.visual?.self * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l125">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.linguistic?.self *
                        10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l126">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.kinesthetic?.self *
                        10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l127">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.naturalist?.self *
                        10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l128">
                    {data
                      ? Math.round(
                        data[0]?.multiple_intelligence?.musical?.self * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l129">
                    {data ? data[0]?.personality?.primary : ""}
                  </span>
                  <span className="absolute l130">
                    {data ? data[0]?.personality?.secondary : ""}
                  </span>
                  <span className="absolute l131">
                    {data
                      ? Math.round(
                        data[0]?.brain_dominance?.left_brain * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l132">
                    {data
                      ? Math.round(
                        data[0]?.brain_dominance?.right_brain * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                </div>
                <div className="pageToCapture">
                  <img src={R43} alt="" className="img-fluid" />
                  <span className="absolute l133">
                    {data
                      ? Math.round(data[0]?.learning_style?.visual * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l134">
                    {data
                      ? Math.round(data[0]?.learning_style?.auditory * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l135">
                    {data
                      ? Math.round(
                        data[0]?.learning_style?.kinesthetic * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l136">
                    {data
                      ? Math.round(data[0]?.leadership_style?.task_oriented)
                      : ""}
                    %
                  </span>
                  <span className="absolute l137">
                    {data
                      ? Math.round(
                        data[0]?.leadership_style?.relationship_oriented
                      )
                      : ""}
                  </span>
                  <span className="absolute l138">
                    {data
                      ? Math.round(data[0]?.stream_selection?.science * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l139">
                    {data
                      ? Math.round(
                        data[0]?.stream_selection?.commerce * 10000
                      ) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l140">
                    {data
                      ? Math.round(data[0]?.stream_selection?.arts * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l141">
                    {data
                      ? Math.round(data[0]?.senses?.touch * 10000) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l142">
                    {data
                      ? Math.round(data[0]?.senses?.taste * 10000) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l143">
                    {data
                      ? Math.round(data[0]?.senses?.smell * 10000) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l144">
                    {data
                      ? Math.round(data[0]?.senses?.listen * 10000) / 100
                      : ""}
                    %
                  </span>
                  <span className="absolute l145">
                    {data ? Math.round(data[0]?.senses?.see * 10000) / 100 : ""}
                    %
                  </span>
                  <span className="absolute l146">
                    {data
                      ? Math.round(data[0]?.thought_process?.action * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l147">
                    {data
                      ? Math.round(data[0]?.thought_process?.thinking * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l148">
                    {data
                      ? Math.round(data[0]?.thought_process?.tactile * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l149">
                    {data
                      ? Math.round(data[0]?.thought_process?.auditory * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <span className="absolute l150">
                    {data
                      ? Math.round(data[0]?.thought_process?.visual * 10000) /
                      100
                      : ""}
                    %
                  </span>
                  <div
                    className="chart absolute c4 p-2"
                    style={{
                      width: "300px",
                      height: "150px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Bar
                      data={{
                        labels: ["IQ", "EQ", "CQ", "AQ"],
                        datasets: [
                          {
                            data: [
                              `${data ? Math.round(data[0]?.quotients?.iq) : ""
                              }`,
                              `${data ? Math.round(data[0]?.quotients?.eq) : ""
                              }`,
                              `${data ? Math.round(data[0]?.quotients?.cq) : ""
                              }`,
                              `${data ? Math.round(data[0]?.quotients?.aq) : ""
                              }`,
                            ],
                            maxBarThickness: 20,
                          },
                        ],
                      }}
                      options={chartExample2.options}
                    />
                  </div>
                </div>
                <div className="pageToCapture">
                  <img src={R44} alt="" className="img-fluid" />
                </div>
              </div>
            </CardBody>
            <div className="btn-container d-flex justify-content-center mb-3">
              <Button
                color="primary update-btn"
                className="px-5"
                onClick={captureAndConvertPages}
                disabled={loader}
              >
                {loader ? <Spinner /> : ""} Print
              </Button>
            </div>
          </Card>
        )}
      </Container>
    </>
  );
};

export default Report;
