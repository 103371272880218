// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap";
import LOGO from "../../assets/img/brand/logo.png";
import DataService from "../../services/requestApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    userToken: "",
    password: "",
    captcha: "",
  });
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin");
    }
  }, []);
  const handleLogin = async () => {
    setLoading(true);
    try {
      const { data } = await DataService.Login(loginData);
      if (data?.token) {
        console.log(data);
        localStorage.setItem("token", data.token);
        localStorage.setItem("UserData", JSON.stringify(data?.data));
        setLoading(false);
        window.location.reload(true);
      } else {
        alert(data?.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-3">
            <div className="text-center text-muted mb-4">
              <img width={80} src={LOGO} alt="" className="mb-2" />
              <br />
              <small>Sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-mobile-button" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="userToken"
                    placeholder="Mobile Number"
                    onChange={handleChange}
                    type="number"
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="password"
                    onChange={handleChange}
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4 px-5 w-100"
                  color="dark"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading && <Spinner size="sm" className="mr-2" />}Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
