import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import NOPHOTO from "../../assets/img/icons/common/noPhoto.png";
import { useEffect, useState } from "react";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import DataService from "../../services/requestApi";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Controller } from "react-hook-form";
import { Image } from "antd";
import queryString from "query-string";

const Profile = () => {
  const AuthToken = localStorage.getItem("token");
  const [data, setData] = useState(false);
  const { control, setValue, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ID = useParams();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await DataService.GetUserByUserId(ID?.id);
      if (data.message === "Success") {
        setData(data?.UserData);
        const newData = data?.UserData;
        setValue("fingerLeftThumb", data?.UserData.fingerLeftThumb);
        setValue("fingerLeftIndex", data?.UserData.fingerLeftIndex);
        setValue("fingerLeftMiddle", data?.UserData.fingerLeftMiddle);
        setValue("fingerLeftRing", data?.UserData.fingerLeftRing);
        setValue("fingerLeftLittle", data?.UserData.fingerLeftLittle);
        setValue("fingerRightThumb", data?.UserData.fingerRightThumb);
        setValue("fingerRightIndex", data?.UserData.fingerRightIndex);
        setValue("fingerRightMiddle", data?.UserData.fingerRightMiddle);
        setValue("fingerRightRing", data?.UserData.fingerRightRing);
        setValue("fingerRightLittle", data?.UserData.fingerRightLittle);
        setLoading(false);
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setLoading(false);
    }
  };
  const setPatternCode = (finger, pattern) => {
    const patternCodes = {
      Arch: "X1",
      "Arch Radial Loop": "R1",
      "Radial Loop": "R",
      "Arch Ulnar Loop": "L1",
      "Ulnar Loop": "L",
      "Centric Whorl": "W4",
      Spiral: "W2",
      "Elongated Whorl": "W3",
      "Double Loop": "W5",
      Accidental: "W",
    };

    const patternCode = patternCodes[pattern];

    if (patternCode) {
      setValue(`${finger}.pattern_code`, patternCode);
    }
  };
  const handleEdit = async () => {
    try {
      setLoading(true);
      const newDATA = getValues();

      for (let key in newDATA) {
        if (newDATA[key]) {
          setPatternCode(key, newDATA[key].fingerprint_pattern);
        }
      }

      const DATA = getValues();
      const formdata = new FormData();
      for (let key in DATA) {
        if (DATA[key]) {
          formdata.append(key, JSON.stringify(DATA[key]));
          console.log(DATA[key]);
        }
      }
      const { data } = await DataService.UpdateFingerprint(ID?.id, formdata);
      if (data.message === "Success") {
        setData(data?.UserData);
        alert("Updated Successfully");
        window.location.reload();
      }
    } catch (error) {
      console.error("Failed to update profile :", error);
    }
    setLoading(false);
  };
  const handleReport = async (id) => {
    try {
      setLoading(true);
      let mydata = {
        userDataId: id,
      };
      const response = await fetch(
        "https://api.dakshtest.com/api/admin/createReport",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AuthToken}`,
            // Do not include "Access-Control-Allow-Origin" here
          },
          body: JSON.stringify(mydata), // Convert the form data to a URL-encoded string
        }
      );
      const data = await response.json();
      // console.log(data);
      if (data) {
        navigate(`/admin/index/user-profile/report/` + id);
      }
    } catch (error) {
      console.error("Report failed:", error);
      setLoading(false);
    }
  };
  return (
    <>
      {/* Page content */}
      <Container className="mt-5 mb-3 " fluid>
        <Row>
          <Col xl="4">
            <Card className="p-3">
              <table className="personlDetails-table">
                <tbody>
                  <tr>
                    <th colSpan={2}>Personal Details</th>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>
                      <b>{data?.dataName?.toUpperCase()}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>
                      <b>
                        {data?.mobile},{data?.phone}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>class</td>
                    <td>
                      <b>{data?.class}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Father Name</td>
                    <td>
                      <b>{data?.father_name}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>DOB</td>
                    <td>
                      <b>{moment(data?.dob).format("ll")}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{data?.status ? "Active" : "Inactive"}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td>
                      {data?.created_at
                        ? moment(data?.created_at).format("LL")
                        : "--"}
                    </td>
                  </tr>
                  <tr>
                    <td>Update Date</td>
                    <td>
                      {data?.updated_at
                        ? moment(data?.updated_at).format("LL")
                        : "--"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
          <Col xl="8">
            <Card className="profileDetails">
              <Row>
                <Col xl="6">
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerLeftThumb?.fingerprint_image
                            ? data?.fingerLeftThumb?.fingerprint_image
                            : NOPHOTO
                        }
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Left Thumb</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerLeftThumb.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerLeftThumb.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerLeftIndex?.fingerprint_image
                            ? data?.fingerLeftIndex?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Left Index</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerLeftIndex.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Finger print pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerLeftIndex.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerLeftMiddle?.fingerprint_image
                            ? data?.fingerLeftMiddle?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Left Middle</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerLeftMiddle.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerLeftMiddle.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerLeftRing?.fingerprint_image
                            ? data?.fingerLeftRing?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Left Ring</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerLeftRing.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerLeftRing.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerLeftLittle?.fingerprint_image
                            ? data?.fingerLeftLittle?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Left Little</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerLeftLittle.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerLeftLittle.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerRightThumb?.fingerprint_image
                            ? data?.fingerRightThumb?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Right Thumb</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerRightThumb.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerRightThumb.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerRightIndex?.fingerprint_image
                            ? data?.fingerRightIndex?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Right Index</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerRightIndex.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerRightIndex.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerRightMiddle?.fingerprint_image
                            ? data?.fingerRightMiddle?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Right Middle</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerRightMiddle.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerRightMiddle.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerRightRing?.fingerprint_image
                            ? data?.fingerRightRing?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Right Ring</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerRightRing.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerRightRing.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="personalDetail">
                    <div className="img_container">
                      <Image
                        width={"100%"}
                        src={
                          data?.fingerRightLittle?.fingerprint_image
                            ? data?.fingerRightLittle?.fingerprint_image
                            : NOPHOTO
                        }
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="fingerDetails">
                      <div className="name">
                        <span>Right Little</span>
                      </div>
                      <FormGroup>
                        <Controller
                          name="fingerRightLittle.fingerprint_pattern"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              type="select"
                              value={value}
                              onChange={onChange}
                            >
                              <option>Fingerprint pattern</option>
                              <option value="Arch">Arch</option>
                              <option value="Arch Radial Loop">
                                Arch Radial Loop
                              </option>
                              <option value="Arch Ulnar Loop">
                                Arch Ulnar Loop
                              </option>
                              <option value="Radial Loop">Radial Loop</option>
                              <option value="Spiral">Spiral</option>
                              <option value="Ulnar Loop">Ulnar Loop</option>
                              <option value="Centric Whorl">
                                Centric Whorl
                              </option>
                              <option value="Elongated Whorl">
                                Elongated Whorl
                              </option>
                              <option value="Double Loop">Double Loop</option>
                              <option value="Accidental">Accidental</option>
                            </Input>
                          )}
                        />
                        <Controller
                          name="fingerRightLittle.ridge_count"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              type=""
                              placeholder="Ridge count"
                            />
                          )}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="btn-container d-flex justify-content-between mb-3">
                {/* <Link
                  to={`/admin/index/user-profile/report/` + data?._id}
                  className="btn update-btn btn-primary"
                >
                  {" "}
                  Report
                </Link> */}
                <Button
                  color="primary update-btn"
                  className=""
                  disabled={loading ? true : false}
                  onClick={() => handleReport(data?._id)}
                >
                  {loading ? <Spinner /> : ""} Report
                </Button>
                <Button
                  color="primary update-btn"
                  className=""
                  disabled={loading ? true : false}
                  onClick={() => handleEdit()}
                >
                  {loading ? <Spinner /> : ""} Update
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
