import http from "./http-common";

class DataService {
  // Login Services
  Login(data) {
    return http.get(
      `admin/login?password=` + data.password + "&mobile=" + data.userToken
    );
  }
  FindAllUsers() {
    return http.get("admin/findAllAdmins");
  }
  FindAllUsersByParams(data) {
    return http.get(
      `admin/findAllAdmins?${
        data?.pageNo ? `page=${data?.pageNo}&limit=${data?.limit}` : data
      }`
    );
  }
  GetAdminByAdminId(id) {
    return http.get("admin/findFingerprintscanByAdminId?" + id);
  }
  UpdateFingerprint(id, data) {
    return http.patch("admin/updateFingerprintscan?userData_id=" + id, data);
  }
  UpdateStatus(id, data) {
    return http.patch("admin/updateRegister?admin_id=" + id, data);
  }
  UpdateUser(id, data) {
    return http.patch("admin/updateFingerprintscan?userData_id=" + id, data);
  }
  CreateReportByUserDataId(data) {
    return http.post("admin/createReport", data);
  }
  GetReportByUserDataId(id) {
    return http.get("admin/findReportByUserDataId?userDataId=" + id);
  }
  GetUserByUserId(id) {
    return http.get("admin/findFingerprintscanById?userData_id=" + id);
  }
  CreateAdmin(data) {
    return http.post("admin/registerAdmin", data);
  }
}

export default new DataService();
